.page-roulettePage {
    width: 100%;
    height: 100%;
    background-image:
        linear-gradient(rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.4)),
        url('https://arcade-web-images.s3.amazonaws.com/backgrounds/roulette_background.webp');
    background-size: cover;
    background-position: center;
}

.roulettePage-game-container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: top;
    padding: 50px 0;
    width: 100%;
    height: 100%;
    gap: 40px;
}