.wallet-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border: 2px solid var(--primary-button);
    padding: 8px 16px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 700;
    border-radius: 4px;
    transition: background-color 0.3s ease, border 0.3s ease;
    background-color: var(--button-wallet);
}

.wallet-button:hover {
    border: 2px solid var(--primary-button-hover);
    background-color: var(--button-wallet-hover);
}

.wallet-button.open {
    border: 2px solid var(--primary-button-active);
    background-color: var(--button-wallet-active);
}

:root {
    --arrow-size: 8px;
    --arrow-color: #FFFFFF;
}

.arrow {
    width: 0;
    height: 0;
    border-left: var(--arrow-size) solid transparent;
    border-right: var(--arrow-size) solid transparent;
    border-bottom: var(--arrow-size) solid var(--arrow-color);
    transition: transform 0.3s ease;
}

.arrow.open {
    border-bottom-color: var(--primary-button);
    transform: rotate(-180deg);
}