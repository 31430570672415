/* The chip itself */
.chip {
    position: relative;
    /* deep red */
    border-radius: 50%;
    box-shadow: 0 4px 0 #999999;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 2px #000;
    overflow: hidden;
}

.chip-amount {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* Inner ring of the chip */
.chip-inner-circle {
    position: absolute;
    width: 65%;
    height: 65%;
    border: dashed white;
    border-radius: 50%;
    z-index: 2;
}

.chip-outer-white {
    position: absolute;
    width: 10%;
    height: 20%;
    background-color: white;
}

.chip-outer-white.one {
    transform: rotate(0deg) translate(450%);
}

.chip-outer-white.two {
    transform: rotate(60deg) translate(450%);
}

.chip-outer-white.three {
    transform: rotate(120deg) translate(450%);
}

.chip-outer-white.four {
    transform: rotate(180deg) translate(450%);
}

.chip-outer-white.five {
    transform: rotate(240deg) translate(450%);
}

.chip-outer-white.six {
    transform: rotate(300deg) translate(450%);
}