.tetrisPage-with-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: none;
    flex-shrink: none;
}

.tetrisPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('https://arcade-web-images.s3.amazonaws.com/backgrounds/blockbuster_background.webp');
    background-size: cover;
    background-position: top;
}

.tetris-titleImage {
    margin: 10px 0px;
    max-width: 800px;
    width: 100%;
    border-radius: 5px;
    border: 2px solid var(--highlight-text-transparency);
    box-sizing: border-box;
}

.tetris-container-outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #152f06;
    border-radius: 15px;
    margin-bottom: 20px;
}

.tetris-container-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px;
    gap: 4px;
    background-color: #428d19;
    border-radius: 5px;
}

.tetris-container-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    gap: 5px;
}

.tetirs-container-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
    height: 603px;
}

.tetris-container-bottom {
    display: flex;
    width: 100%;
    background-color: rgb(87, 50, 0);
    border-radius: 5px;
}

.tetris-modeSelect {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 5px;
    /* background-color: rgba(0, 0, 0, 0.908); */
    width: 100%;
    padding: 50px 0px;
}

.tetris-modeSelect-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    height: 400px;
    color: var(--secondary-text);
    background-color: #121212f8;
    transition: all 0.1s ease-in-out;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    padding: 10px 0px;

    /*Mobile width 1200 or less*/
    @media (max-width: 1200px) {
        width: 150px;
        height: 300px;
    }
}

.tetris-modeSelect-button:hover {
    background-color: #121212;
    transform: scale(1.05);
    color: var(--primary-text);
}

.tetris-modeSelect-button-title {
    font-size: 35px;
    font-weight: bold;

    /*Mobile width 1200 or less*/
    @media (max-width: 1200px) {
        font-size: 25px;
    }
}

.tetris-modeSelect-button-tier {
    font-size: 20px;
    font-weight: bold;

    /*Mobile width 1200 or less*/
    @media (max-width: 1200px) {
        font-size: 20px;
    }
}

.tetris-modeSelect-button-description {
    font-size: 45px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    /*Mobile width 1200 or less*/
    @media (max-width: 1200px) {
        font-size: 35px;
    }
}

/* Mobile styles for when width is less than 1200 */
@media (max-width: 1200px) {
    .tetris-container-outer {
        padding: 5px;
        border-radius: 5px;
    }

    .tetris-container-inner {
        padding: 2px;
    }

    .tetirs-container-top {
        height: 364px;
    }
}

.tetris-board {
    display: flex;
    flex-direction: column;
    border: 2px solid black;
}

.tetris-row {
    display: flex;
}

.tetris-cell {
    width: 20px;
    /* Adjust size as needed */
    height: 20px;
    border: 1px solid #ddd;
    box-sizing: border-box;
}

.tetris-cell.filled {
    background-color: blue;
    /* Change color as needed */
}

.blockbuster-disclaimer {
    background-color: #121212;
    font-size: 12px;
    padding: 15px;
    color: var(--primary-text);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    border-radius: 5px;
    box-shadow: 0 0 10px black;
    margin: 15px 0px;
}