.nft-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.nft-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 120px;
    max-width: 1250px;
    gap: 5px;

    /* Mobile adjustments */
    @media (max-width: 1200px) {
        gap: 3px;
    }
}

.nft-item {
    width: 120px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    transition: all 0.15s;
    background-color: var(--primary-dark);
    border: 1px solid rgb(176, 176, 176);

    /* Mobile adjustments */
    @media (max-width: 1200px) {
        width: 80px;
    }
}

.nft-item:hover {
    background-color: var(--primary);
    transform: scale(1.03);
}

.nft-item:active {
    background-color: var(--primary);
    transform: scale(0.97);
}

.nft-item:hover .nft-item-bottom-container {
    background-color: var(--positive-clr);
}

.nft-item-bottom-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-size: 12px;
    font-weight: bold;
    color: var(--highlight-text);
}

.nft-item img {
    width: 100%;
}

.nft-item-text {
    font-size: 12px;
    font-weight: bold;
    color: var(--highlight-text);
}

.nft-item-bottom-container.selected {
    background-color: var(--positive-clr);
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-controls span {
    margin: 0 12px;
    color: var(--secondary-text);
}

.pagination-button {
    width: 100px;
    border: none;
}

.pagination-button:disabled {
    background-color: var(--tertiary-dark);
    color: var(--secondary-text);
    cursor: not-allowed;
}

.nft-list-select-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 350px;
    width: 100%;
}

.nft-list-select-buttons-container .button {
    margin: 0 5px;
    width: 120px;
    border-radius: 3px;
}