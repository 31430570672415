/* hudMobile.css */
.mobile-hud-fixed-position {
    position: fixed;
    bottom: 0px;
    right: 0;
    z-index: 1000;
    width: 100%;
    transition: bottom 0.25s ease-in-out;
    /* Smooth transition for sliding in and out */

    /*Desktop*/
    @media (min-width: 1200px) {
        display: none;
    }
}

.mobile-hud-retracted {
    bottom: -50px;
    /* Retract almost fully, leaving a tab visible */
}

.mobile-hud-balance-container {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: var(--secondary-dark);
    box-shadow: 0 2px 4px var(--black-square);
    border-top: var(--dotted-border-solid);
    border-radius: 50% 50% 0 0;
    padding: 15px;
    width: 100%;
    cursor: pointer;

}

.mobile-hud-balance-display-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.mobile-hud-balance-display-container-extras {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.mobile-hud-balance-display {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    color: var(--primary-text);
}

.mobile-hud-balance-change {
    display: flex;
    justify-content: flex-end;
    width: 130px;
    position: absolute;
    font-size: 30px;
    font-weight: 600;
    top: 35px;
    left: 0px;
    animation: fadeOut 5s ease-out;
}

.mobile-hud-balance-change.increase {
    color: var(--win-clr-light);
}

.mobile-hud-balance-change.decrease {
    color: var(--lose-clr-light);
}