.tetrisCell {
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;
    box-shadow: inset -3px 0px 3px -3px rgba(0, 0, 0, 0.7);
    border-bottom: 2px solid;
    border-top: 3px solid;
    border-right: 2px solid;
    border-left: 1px solid;

}

.tetrisCell.E {
    background-color: var(--tetris-cell-Empty);
    box-shadow: none;
    border-radius: 0px;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.081);
}

.tetrisCell.I {
    background-color: var(--tetris-cell-I);
    border-color:
        var(--tetris-cell-I-lighter) var(--tetris-cell-I-darker) var(--tetris-cell-I-darker) var(--tetris-cell-I-lighter);
}

.tetrisCell.J {
    background-color: var(--tetris-cell-J);
    border-color:
        var(--tetris-cell-J-lighter) var(--tetris-cell-J-darker) var(--tetris-cell-J-darker) var(--tetris-cell-J-lighter);
}

.tetrisCell.L {
    background-color: var(--tetris-cell-L);
    border-color:
        var(--tetris-cell-L-lighter) var(--tetris-cell-L-darker) var(--tetris-cell-L-darker) var(--tetris-cell-L-lighter);
}

.tetrisCell.O {
    background-color: var(--tetris-cell-O);
    border-color:
        var(--tetris-cell-O-lighter) var(--tetris-cell-O-darker) var(--tetris-cell-O-darker) var(--tetris-cell-O-lighter);
}

.tetrisCell.S {
    background-color: var(--tetris-cell-S);
    border-color:
        var(--tetris-cell-S-lighter) var(--tetris-cell-S-darker) var(--tetris-cell-S-darker) var(--tetris-cell-S-lighter);
}

.tetrisCell.T {
    background-color: var(--tetris-cell-T);
    border-color:
        var(--tetris-cell-T-lighter) var(--tetris-cell-T-darker) var(--tetris-cell-T-darker) var(--tetris-cell-T-lighter);
}

.tetrisCell.Z {
    background-color: var(--tetris-cell-Z);
    border-color:
        var(--tetris-cell-Z-lighter) var(--tetris-cell-Z-darker) var(--tetris-cell-Z-darker) var(--tetris-cell-Z-lighter);
}

.tetrisCell.G {
    background-color: var(--tetris-cell-Empty);
    border-width: 1px;
}

/* Mobile styles for when width is less than 1200 */
@media only screen and (max-width: 1200px) {
    .tetrisCell {
        width: 18px;
        height: 18px;
    }
}