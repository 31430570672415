.zoomerStaking-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-image:
        linear-gradient(rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)),
        url('https://arcade-web-images.s3.amazonaws.com/backgrounds/stakezoomers_background.webp');
    background-size: cover;
    background-position: center;
}

.zoomerStaking-page-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    margin: 10px 0px;
    width: 100%;
    max-width: 1200px;
    min-height: 360px;
}

.zoomerStaking-page-button {
    max-width: 200px;
}

.zoomerStaking-page-subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 20px;
    font-weight: bold;
    color: var(--highlight-text);
}