.user-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 1em;
    color: var(--primary-text);
}

.address,
.balance {
    margin: 0 5px;
}
