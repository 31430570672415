.buttonSpinner {
    border: 3px solid rgba(255, 255, 255, 0.762);
    /* Lighter grey, semi-transparent */
    border-top-color: rgb(123, 123, 123);
    /* White, change as needed */
    border-radius: 50%;
    /* Smaller size for embedding in buttons */
    animation: buttonSpin 1s linear infinite;
    /* keep square aspect ratio*/
    aspect-ratio: 1 / 1;
}

@keyframes buttonSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}