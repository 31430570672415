:root {
    --bj-game-width: 700px;
    --bj-game-height: 700px;
    --bj-game-card-width: 75px;
    --bj-hand-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.6);
    --bj-white-text: rgb(221, 221, 221);

    --bj-font-size-20: 20px;
    --bj-font-size-25: 25px;
    --bj-font-size-35: 35px;
}

.blackjackGame-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #090909;
    border-radius: 5px;
    width: var(--bj-game-width);
    height: var(--bj-game-height);
    border: 3px solid #000000;
    box-sizing: border-box;
    overflow: hidden;
}

.blackjackGame-playerhand {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px;
    gap: 50px;
    width: 100%;
    cursor: pointer;
    flex: 1;
    transition: background-position 0.15s ease-in-out;
    position: relative;
    box-shadow: var(--bj-hand-shadow);
    background-position: -25px 25px;
    background-size: 50px;
    overflow: hidden;
}

.blackjackGame-playerhand-handState {
    position: absolute;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--bj-font-size-25);
    font-weight: bold;
    width: 250px;
    height: 80px;
    color: black;
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    border: 5px solid black;
    transform: rotate(-45deg)
}

.blackjackGame-Blackjack {
    background-color: black !important;
    color: var(--bj-white-text) !important;
}

.blackjackGame-Lose {
    background-color: rgb(155, 10, 53) !important;
    color: var(--bj-white-text) !important;
}

.blackjackGame-Playing {
    background-color: rgb(27, 153, 58) !important;
    color: rgb(220, 220, 220) !important;
}

.blackjackGame-Win {
    background-color: rgb(27, 153, 58) !important;
    color: rgb(220, 220, 220) !important;
}

.blackjackGame-Bust {
    background-color: rgb(155, 10, 53) !important;
    color: rgb(220, 220, 220) !important;
}

.blackjackGame-Tie {
    background-color: #cd9300 !important;
    color: rgb(220, 220, 220) !important;
}

.blackjackGame-21 {
    background-color: rgb(6, 165, 168) !important;
    color: rgb(220, 220, 220) !important;
}

.blackjackGame-Stand {
    background-color: rgb(6, 165, 168) !important;
    color: rgb(220, 220, 220) !important;
}

.blackjackGame-playerhand:hover {
    background-position: -15px 15px;
}

.blackjackGame-playerhand.selected {
    justify-content: flex-end;
}

.blackjackGame-playerhand-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.blackjackGame-playerhand-cardValue {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    font-size: var(--bj-font-size-35);
    font-weight: bold;
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    box-sizing: border-box;
    border: 5px solid rgb(0, 0, 0);
    z-index: 1;
}

.blackjackGame-playerhand-cardValue.Acehand {
    font-size: var(--bj-font-size-25)
}

.blackjackGame-bg {
    background-position: -25px 25px;
    background-size: 50px;
}

.blackjackGame-bg.one {
    background-color: #ab1ab520;
    background-image: url('https://arcade-web-images.s3.amazonaws.com/icons/spade_bg2.webp');
}

.blackjackGame-bg.one.selected {
    background-color: #ab1ab5;
}

.blackjackGame-bg.two {
    background-color: #de711880;
    background-image: url('https://arcade-web-images.s3.amazonaws.com/icons/diamond_bg2.webp');
}

.blackjackGame-bg.two.selected {
    background-color: #de7118;
}

.blackjackGame-bg.three {
    background-color: #d83b9980;
    background-image: url('https://arcade-web-images.s3.amazonaws.com/icons/heart_bg2.webp');
}

.blackjackGame-bg.three.selected {
    background-color: #d83b99;
}

.blackjackGame-bg.four {
    background-color: #22b3ab80;
    background-image: url('https://arcade-web-images.s3.amazonaws.com/icons/clover_bg2.webp');
}

.blackjackGame-bg.four.selected {
    background-color: #22b3ab;
}

.blackjackGame-dealerhand {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px;
    flex-wrap: nowrap;
    gap: 5px;
    width: 100%;
    box-shadow: var(--bj-hand-shadow);
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.7)),
        url('https://arcade-web-images.s3.amazonaws.com/titleCards/hit_me_clean_comp_small.webp');
    background-size: cover;
    background-position: center;
    flex: 1;
    overflow: hidden;
}

.blackjackGame-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    gap: 5px;
}

.blackjackGame-card {
    flex: 1;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    max-width: var(--bj-game-card-width);
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
    transform: perspective(200px) rotateY(10deg) rotateX(3deg);
}

.blackjackGame-card.selected {
    transform: perspective(300px) rotateY(-20deg) rotateX(-9deg);
}

.blackjackGame-actions-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 5px;
    width: 100%;
    padding: 5px;
    flex: 1;
    box-sizing: border-box;
}

.blackjackGame-button {
    width: 100px;
}

.blackjackGame-newGame-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    gap: 3px;
}

.blackjackGame-newGame-input {
    width: 80px;
    height: 40px;
    font-size: var(--bj-font-size-20);
    color: rgb(0, 0, 0);
    background-color: rgb(231, 231, 231);
    border-radius: 5px;
    box-sizing: border-box;
    border: 2px solid rgb(0, 0, 0);
    padding: 5px;
    text-align: center;

}

.blackjackGame-newGame-input:focus {
    outline: none;
}

.blackjackGame-endresult-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    gap: 65px;
}

.blackjackGame-endresult-container-section {
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    /* transform: rotate(45deg);
    background-color: rgb(27, 153, 58); */
    z-index: 0;
    width: 100px;
    gap: 10px;
    font-weight: bold;
}

.blackjackGame-endresult-container-section::after {
    position: absolute;
    content: '';
    box-sizing: border-box;
    height: 110px;
    width: 300px;
    z-index: -1;
    transform: rotate(45deg);
}

.blackjackGame-endresult-container-section:nth-child(1)::after {
    background-color: #a10a95bb;
}

.blackjackGame-endresult-container-section:nth-child(2)::after {
    background-color: #129d96ba;
}

.blackjackGame-endresult-container-section:nth-child(3)::after {
    background-color: rgb(155, 10, 53);
}

.blackjackGame-endresult-container-section-title {
    font-size: var(--bj-font-size-20);
    display: flex;
    flex-direction: row;
    width: 100%;
    transform: translateX(-15px);
}

.blackjackGame-endresult-container-section-value {
    font-size: var(--bj-font-size-25);
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    width: 120px;
    transform: translateX(35px);
    gap: 5px;
}

.blackjackGame-newGame-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
}

.blackjackGame-newGame-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    font-weight: bold;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 2px;
    max-width: 130px;
    max-height: 60px;
}

.blackjackGame-newGame-buttons.tertiary {
    background-color: var(--tertiary-button);
    color: var(--tertiary-button-text);
}

.blackjackGame-newGame-buttons.tertiary:hover {
    background-color: var(--tertiary-button-hover);
    color: var(--tertiary-button-hover-text);
}

.blackjackGame-newGame-buttons.tertiary:active {
    background-color: var(--tertiary-button-active);
    color: var(--tertiary-button-active-text);
}

.blackjackGame-newGame-buttons.primary {
    background-color: var(--primary-button);
    color: var(--primary-button-text);
}

.blackjackGame-newGame-buttons.primary:hover {
    background-color: var(--primary-button-hover);
    color: var(--primary-button-text-hover);
}

.blackjackGame-newGame-buttons.primary:active {
    background-color: var(--primary-button-active);
    color: var(--primary-button-active-text);
}

.blackjackGame-newGame-buttons.disabled {
    background-color: var(--tertiary-dark) !important;
    color: var(--secondary-text) !important;
    cursor: not-allowed !important;
}

.blackjackGame-currentBet-display {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1;
    height: 100%;
    position: relative;
}

.blackjackGame-currentBet-display-title {
    position: absolute;
    top: 5%;
    color: rgb(188, 188, 188);
    font-size: var(--bj-font-size-25);
    font-weight: bold;
    background-color: #22b3ab;
}

.blackjackGame-currentBet-display-value {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: var(--bj-font-size-35);
    font-weight: bold;
    color: rgb(225, 225, 225);
}

.blackjackGame-playAmount-button {
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(27, 153, 58);
    color: rgb(230, 230, 230);
    font-size: var(--bj-font-size-25);
    font-weight: bold;
    height: 100%;
    flex: 1;
    cursor: pointer;
    margin: 1px;
}

.blackjackGame-playAmount-button:hover {
    background-color: rgb(7, 92, 28);
    color: rgb(237, 237, 237);
}

.blackjackGame-playAmount-button.disabled {
    background-color: #1c1c1c;
    /* color: rgb(66, 63, 63); */
    cursor: not-allowed;
    opacity: 0.5;
}


/*Mobile settings under 1200px width*/
@media (max-width: 1200px) {
    :root {
        --bj-game-width: 355px;
        --bj-game-height: 500px;
        --bj-game-card-width: 60px;

        --bj-font-size-20: 12px;
        --bj-font-size-25: 17px;
        --bj-font-size-35: 27px;
    }

    .blackjackGame-playerhand-handState {
        width: 165px;
        height: 45px;
    }

    .blackjackGame-playerhand-cardValue {
        height: 55px;
        width: 55px;
    }

    .blackjackGame-endresult-container-section {
        width: 42px;
        gap: 15px;
        margin-right: 33px;
    }

    .blackjackGame-endresult-container-section-value {
        width: 22px;
    }

    .blackjackGame-endresult-container-section::after {
        height: 55px;
    }

    .blackjackGame-endresult-container {
        gap: 0px;
    }

    .blackjackGame-newGame-buttons {
        border-radius: 5px;
        max-width: 95px;
        max-height: 45px;
    }

    .blackjackGame-newGame-buttons-container {
        flex: 2;
    }
}