:root {
    --pass-redeem-border-primary: 1px solid var(--soft-background);
}

.passRedeem-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.passRedeem-stats-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 35px;
    max-width: 1200px;
    width: 100%;
    margin-top: 50px;

    @media screen and (max-width: 1200px) {
        gap: 10px;
        margin-top: 10px;
    }
}

.passRedeem-stats-container-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1200px) {
        width: 160px;
    }
}

.passRedeem-stats-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 16px;
    width: 100%;
    height: 100%;
    font-weight: 600;
    color: var(--secondary-text);

    @media screen and (max-width: 1200px) {
        font-size: 12px;
        padding-left: 0px;
        height: 25px;
        margin-top: 20px;
        justify-content: flex-start;
    }
}

.passRedeem-stats-title.merch {
    padding-left: 0px;
}

.passRedeem-stats-value {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
    width: 100%;
    height: 80px;
    font-size: 55px;
    font-weight: bold;
    color: var(--highlight-text);

    @media screen and (max-width: 1200px) {
        font-size: 33px;
        height: 50px;
        justify-content: flex-start;
    }
}

.passRedeem-stats-value-merch-text {
    font-style: italic;
}

.passRedeem-stats-value-merch {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    font-weight: bold;
    line-height: 1;
    color: var(--highlight-text);

}

.passRedeem-stats-value-merch-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 80px;
    font-size: 15px;
    font-weight: bold;
    color: var(--highlight-text);

    @media screen and (max-width: 1200px) {
        height: 50px;
    }
}

.passRedeem-stats-value-merch-container-title {
    font-weight: bold;
    color: var(--secondary-text);
}

.passRedeem-info-grid-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0px;
}

/* 2x2 grid */
.passRedeem-info-grid {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 400px 400px;
    grid-template-rows: 400px 400px;
    gap: 4px;

    @media screen and (max-width: 1200px) {
        grid-template-columns: 340px;
        grid-template-rows: 340px 340px 340px 340px;
    }
}

.passRedeem-info-grid-item {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    position: relative;
    border: var(--pass-redeem-border-primary);
    overflow: hidden;
}

.passRedeem-info-grid-item::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.7;
    background-size: cover;
    background-position: center;
}

.passRedeem-info-grid-item.scratch::after {
    background-image: url('https://arcade-web-images.s3.amazonaws.com/backgrounds/pass_redeem_card.webp');
}

.passRedeem-info-grid-item.permaFarmPoints::after {
    background-image: url('https://arcade-web-images.s3.amazonaws.com/backgrounds/farm_background.webp');
}

.passRedeem-info-grid-item.pg::after {
    background-image: url('https://arcade-web-images.s3.amazonaws.com/backgrounds/pg_background.webp');
}

.passRedeem-info-grid-item.merch::after {
    background-image: url('https://arcade-web-images.s3.amazonaws.com/backgrounds/merc_background.webp');
}

.passRedeem-info-grid-item-title {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    gap: 6px;
    z-index: 1;
    color: var(--highlight-text);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.97);
}

.passRedeem-info-grid-item-title-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.passRedeem-info-grid-item-title-section.extra {
    font-size: 12px;
    color: var(--secondary-text);
}


.passRedeem-info-grid-item-extra {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 13px;
    font-weight: 600;
    z-index: 1;
    color: var(--primary-text);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.97);

    @media screen and (max-width: 1200px) {
        font-size: 11px;
    }
}

.passRedeem-info-grid-item-extra-highlight {
    font-size: 25px;
    font-weight: bold;
    color: var(--highlight-text);

    @media screen and (max-width: 1200px) {
        font-size: 15px;
    }
}

.passRedeem-info-grid-passes-container {
    display: grid;
    grid-template-columns: 60px 80px 100px 120px;
    grid-template-rows: 120px;
    gap: 10px;
    margin-bottom: 10px;

    @media screen and (max-width: 1200px) {
        grid-template-columns: 50px 70px 90px 110px;
        grid-template-rows: 120px;
        gap: 5px;
    }

    z-index: 1;
}

.passRedeem-info-grid-pass {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    position: relative;
    box-shadow: 10px 7px 3px rgb(0, 0, 0);
}

.passRedeem-info-grid-pass.yellow {
    height: 40%;
}

.passRedeem-info-grid-pass.blue {
    height: 60%;
}

.passRedeem-info-grid-pass.green {
    height: 80%;
}

.passRedeem-info-grid-pass.red {
    height: 100%;
}

.passRedeem-info-grid-pass-img {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    border: var(--pass-redeem-border-primary);
}

.passRedeem-info-grid-pass-value {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40%;
    z-index: 1;
    font-weight: bold;
    color: var(--highlight-text);
    background-color: rgba(0, 0, 0, 1);
    gap: 2px;
    font-style: italic;
    border-bottom: var(--pass-redeem-border-primary);
    border-right: var(--pass-redeem-border-primary);
    border-left: var(--pass-redeem-border-primary);
}

.passRedeem-info-grid-pass-value.yellow {
    font-size: 20px;
}

.passRedeem-info-grid-pass-value.blue {
    font-size: 25px;
}

.passRedeem-info-grid-pass-value.green {
    font-size: 30px;
}

.passRedeem-info-grid-pass-value.red {
    font-size: 35px;
}

.passRedeem-steps-text {
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
    color: var(--highlight-text);
    opacity: 0.8;
    margin-top: 50px;
    text-align: center;

    @media screen and (max-width: 1200px) {
        margin-top: 25px;
    }

}

.passRedeem-steps-text-extra {
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: var(--secondary-text);
    opacity: 0.7;
}

.passRedeem-blurb {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1200px;
    font-size: 16px;
    font-weight: 600;
    color: var(--secondary-text);
    text-align: center;
    padding: 0px 0px;

    @media screen and (max-width: 1200px) {
        width: 330px;
        font-size: 12px;
        padding: 0px;
    }
}