.dw-history-container {
    width: 100%;
    min-height: 500px;
    margin: 10px 0 0 0;
    flex-shrink: 0;
}

/*Make table cell appear clickable, give it some depth*/
/*Also slightly change background color opacity*/
.dw-history-table-txhash-cell {
    cursor: pointer;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.2);
}

.dw-history-table-txhash-cell:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.3);
}

/* Mobile classes for width less than 1200px*/
@media (max-width: 1200px) {
    .dw-history-container {
        min-height: 450px;
    }
}