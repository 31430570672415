/* Header.css */
.header {
    background-color: var(--primary-dark);
    height: var(--header-height);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 25px;
    color: var(--primary-text);
    padding: 0 2rem;
    position: relative;
    border: var(--dotted-border-primary);
    box-sizing: border-box;
}

.logo-container {
    flex: 1;
    display: flex;
    justify-content: flex-start;
}

.menu-bar-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.menu-bar-socials-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 25px;
}

.menu-bar-social {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
}

.user-panel-container {
    width: 150px;
    display: flex;
    justify-content: flex-end;
}

.header-extra-mobile-only {
    display: none;
}

.menu-bar-rightside-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 1200px) {
    .header {
        justify-content: space-between;
        padding: 0 0.8rem;
    }

    .menu-bar-container,
    .menu-bar-socials-container {
        display: none !important;
    }

    .logo-container,
    .user-panel-container {
        flex: none;
    }

    .header-extra-mobile-only {
        display: flex;
        justify-content: center;
        gap: 30px;
        width: 100%;
        background-color: var(--primary-dark);
        padding: 0.5rem 0;
    }
}

@media (min-width: 1201px) {
    .hamburger-menu-container {
        display: none;
    }

    .mobile-menu {
        display: none;
    }
}