/* farmCard.css */
.farm-card {
    background-color: var(--secondary-dark);
    border-radius: 10px;
    color: var(--primary-text);
    max-width: 400px;
    width: 100%;
    margin: 20px 5px;
    /* The box-shadow is now controlled by the animation */
    animation: glow 2s infinite alternate;
    transition: all linear;
}

@keyframes glow {
    from {
        box-shadow: 0px 0px 5px 0px var(--primary-button);
    }

    to {
        box-shadow: 0px 0px 20px 0px var(--primary-button);
    }
}

.farm-card-header {
    background-color: var(--primary-dark);
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
}

.farm-card-header-title {
    margin: 0;
    padding: 0;
    font-size: 1.5em;
    color: var(--highlight-text);
}

.farm-card-header-subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 10px;
    font-size: 1em;
    color: var(--secondary-text);
}

.farm-card-body {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 20px;
}

.farm-card-body-pot,
.farm-card-share-price-value {
    text-align: center;
}

.farm-card-body-pot-title,
.farm-card-share-price-title {
    font-size: 1.2em;
    color: var(--secondary-text);
    margin-bottom: 5px;
}

.farm-card-body-pot-value,
.farm-card-share-price-value {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 1.4em;
    font-weight: bold;
}

/* If you need specific button styles inside the card, you can add them here, using your button classes as a starting point */