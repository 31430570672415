.history-container {
    width: 100%;
    min-height: 700px;
    margin: 10px 0 0 0;
    background-color: var(--soft-background);
    flex-shrink: 0;
}

.history-table-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.history-title {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: var(--highlight-text);
    margin: 50px 0px 20px 0px;
}

.history-table {
    width: 100%;
    max-width: 1200px;
    min-width: 350px;
    padding: 5px;
    color: var(--primary-text);
    font-size: 15px;
    table-layout: fixed;
    /* Add this line */
    border-collapse: collapse;
    /* Optional, for styling */
    font-family: Roboto Condensed;
}

.history-table th,
.history-table td {
    padding: 5px;
    text-align: center;
    border-bottom: 1px solid var(--secondary-dark);
    /* For td, it's optional based on your styling preference */
}

.history-table th {
    position: relative;
    padding: 5px;
    border-bottom: 1px solid var(--secondary-dark);
    text-align: center;
    font-weight: bold;
    background-color: var(--secondary-dark);
    color: var(--highlight-text);
    cursor: pointer;
}

.history-table th:hover {
    background-color: var(--tertiary-dark);
}

.history-table th.selected {
    background-color: var(--tertiary-dark);
}

.history-table td {
    padding: 5px 0px;
    text-align: center;
}

.history-table tr.positive {
    background-color: var(--positive-clr);
}

.history-table tr.negative {
    background-color: var(--negative-clr);
}

.history-table tr.neutral {
    background-color: var(--neutral-clr);
}

.history-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0px;
    gap: 15px;
    color: var(--primary-text);
    font-weight: 900;
    font-family: digital-7 mono;
    font-size: 20px;
}

.history-pagination-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--highlight-text);
    font-size: 30px;
    cursor: pointer;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.history-pagination-btn:hover {
    background-color: var(--tertiary-dark);
}

.history-sorting-arrow {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--highlight-text);
}

/* Mobile classes for width less than 1200px*/
@media (max-width: 1200px) {
    .history-table {
        font-size: 11px;
    }

    .history-sorting-arrow {
        right: 2px;
    }

    .history-container {
        min-height: 600px;
    }
}