.page-lobby {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* min-height: calc(100vh - var(--header-height)); */
    /* max-height: calc(100vh - var(--header-height)); */
    height: calc(100vh - var(--header-height));
    width: 100%;
    font-weight: 600;

    @media screen and (max-width: 1200px) {
        height: 100%;
    }
}

.lobby-options-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 10px;
}

.lobby-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 1200px) {
        flex: none;
    }
}

.lobby-section::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.2;
    transition: all 0.2s ease;
    background-position: center top -550px;
    background-size: cover;

    @media screen and (max-width: 1200px) {
        background-position: center;
    }
}

.lobby-section:hover::after {
    opacity: 0.6;
    background-position: center top -350px;
}

.lobby-section.roulette::after {
    background-image: url('https://arcade-web-images.s3.amazonaws.com/backgrounds/roulette_background.webp');
}

.lobby-section.blockbuster::after {
    background-image: url('https://arcade-web-images.s3.amazonaws.com/backgrounds/blockbuster_background.webp');
}

.lobby-section.hitme::after {
    background-image: url('https://arcade-web-images.s3.amazonaws.com/backgrounds/hitme_background.webp');
}

.lobby-section.scratchfarm::after {
    background-image: url('https://arcade-web-images.s3.amazonaws.com/backgrounds/farm_background.webp');
}

.lobby-section.zoomerstaking::after {
    background-image: url('https://arcade-web-images.s3.amazonaws.com/backgrounds/stakezoomers_background.webp');
}

.lobby-section.account::after {
    background-image: url('https://arcade-web-images.s3.amazonaws.com/bg2.png');
}

.lobby-section-title {
    height: 80%;
    transition: all 0.2s ease;
    z-index: 1;
    border: 3px solid var(--highlight-text-transparency);
    border-radius: 3px;
    box-sizing: border-box;

    @media screen and (max-width: 1200px) {
        height: auto;
        width: 100%;
    }
}

.lobby-section:hover .lobby-section-title {
    height: 100%;
    max-width: 100%;
    /*Make it so aspect ratio doesnt change*/
}

.lobby-section-underc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    position: relative;
    overflow: hidden;
    opacity: 0.5;

    @media screen and (max-width: 1200px) {
        flex: none;
    }
}

.lobby-section-title-underc {
    height: 80%;
    transition: all 0.2s ease;
    z-index: 1;
    border: 3px solid var(--highlight-text-transparency);
    border-radius: 3px;
    box-sizing: border-box;
    opacity: 0.7;

    @media screen and (max-width: 1200px) {
        height: auto;
        width: 100%;
    }
}

.lobby-section-underc::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 100%; */
    height: 100vh;
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 0;
    opacity: 0.5;
    transition: all 0.2s ease;
    background-position: center;
    background-size: 50%;
}

.lobby-section-underc.horderunner::after {
    background-image: url('https://arcade-web-images.s3.amazonaws.com/backgrounds/underc.png');
}

.lobby-section-underc.beerrun::after {
    background-image: url('https://arcade-web-images.s3.amazonaws.com/backgrounds/underc.png');
}