.tetris-page-footer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--primary-dark);
    width: 100%;
    min-height: 300px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    box-shadow: 0px 0px 10px 0px #000000;
}

.active-games-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 20px 0px;
}

.active-games-title {
    width: 100%;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: var(--primary-text);
}

.active-games-cards {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    background-color: var(--primary-dark);
    padding: 20px;
    max-width: 1200px;
}

.active-game-card {
    box-sizing: border-box;
    background-color: var(--secondary-dark);
    color: var(--primary-text);
    border: 1px solid var(--soft-background);
    padding: 10px;
    border-radius: 8px;
    width: 200px;
    transition: transform 0.2s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.active-game-card.inplay {
    background-color: var(--draw-clr);
}

.active-game-card.win {
    background-color: var(--win-clr);
}

.active-game-card.lose {
    background-color: var(--lose-clr);
}

.active-game-card-boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2px;
}

.active-game-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.active-game-card img {
    width: 100%;
    height: auto;
    border-radius: 4px;
}

.game-details-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    flex: 1;
    background-color: var(--primary-dark);
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--soft-background);
    min-height: 35px;
}

.game-details-box.big {
    min-width: 80%;
}

.game-details-box.small {
    min-width: 40%;
}

.game-details-box-name {
    font-size: 9px;
    font-weight: bold;
    color: #757575;
}


.game-details-box-value {
    font-size: 11px;
    color: #d4d4d4;
}

/* Tier specific styles */
.bronze-tier {
    border-color: var(--bronze-button);
}

.silver-tier {
    border-color: var(--silver-button);
}

.gold-tier {
    border-color: var(--gold-button);
}

/* Add to tetrisFooter.css */
.game-history-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: var(--primary-dark);
    width: 100%;
    padding: 20px 0px;
}

.game-history-title {
    width: 100%;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: var(--primary-text);
}

.game-history-list {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
}

.game-history-card {
    box-sizing: border-box;
    background-color: var(--secondary-dark);
    color: var(--primary-text);
    border: 1px solid var(--soft-background);
    padding: 15px;
    border-radius: 8px;
    width: 250px;
    transition: transform 0.2s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.game-history-card-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
    color: var(--secondary-text);
}

.game-history-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}