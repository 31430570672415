.passRedeemCodeTable {
    color: var(--secondary-text);
    padding: 20px;
    border-radius: 10px;
    width: 340px;
    opacity: 0.9;
}

.passRedeemCodeTable table {
    width: 100%;
    border-collapse: collapse;
}

.passRedeemCodeTable th,
.passRedeemCodeTable td {
    border: 1px solid var(--highlight-text-transparency);
    padding: 8px;
    text-align: center;
}

.passRedeemCodeTable th {
    background-color: var(--highlight-text);
    color: var(--primary-button-text);
}

.passRedeemCodeTable-pagination {
    margin-top: 20px;
    text-align: center;
}

.passRedeemCodeTable-pagination button {
    padding: 5px 10px;
    margin: 0px 10px;
    background-color: var(--highlight-text-transparency);
    color: var(--primary-button-text);
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.passRedeemCodeTable-pagination button:disabled {
    background-color: var(--tertiary-dark);
    cursor: not-allowed;
}