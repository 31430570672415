.connect-button {
    display: block;
    border: none;
    padding: 12px 24px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

/* Mobile */
@media (max-width: 1200px) {
    .connect-button {
        padding: 8px 20px;
        font-size: 0.8rem;
    }
}