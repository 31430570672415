.home {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    gap: 100px;

    @media screen and (max-width: 1200px) {
        gap: 50px;
    }
}

.home-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(circle at center, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 1) 100%), url("https://arcade-web-images.s3.amazonaws.com/home_bg.png");
    background-size: cover;
    background-position: center;
    filter: grayscale(60%) blur(2px);
    z-index: -1;
    opacity: 1;
}


.enter-button {
    text-decoration: none;
    font-size: 30px !important;
    outline: solid rgba(0, 0, 0, 0.8) 5px;
}

.enter-button:hover {
    outline: solid rgba(0, 0, 0, 0.9) 5px;
}

.enter-button:active {
    outline: solid rgba(0, 0, 0, 1) 5px;
}

.home-logo-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 900px;
    gap: 20px;


    @media screen and (max-width: 1200px) {
        max-width: 330px;
        gap: 10px;
    }
}

.home-logo-circle {
    width: 200px;

    @media screen and (max-width: 1200px) {
        max-width: 90px;
    }
}