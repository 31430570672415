.roulette-bet-board-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.roulette-bet-board-history {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50px;
    background-color: rgb(0, 0, 0);
    height: 776px;
    border-radius: 0px 0px 10px 0px;
    font-size: 13px;
    color: rgb(28, 131, 28);
    outline: 1px dashed rgb(117, 117, 117);
}

.roulette-bet-board-history-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
}

.roulette-bet-board-history-row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
}

.roulette-bet-board-history-row.red {
    justify-content: end;
    color: rgb(184, 42, 42);
}

.roulette-bet-board-history-row.black {
    justify-content: start;
    color: rgb(207, 207, 207)
}

.roulette-grid {
    display: grid;
    grid-template-columns: 45px 45px repeat(3, 45px) 45px;
    grid-template-rows: 45px repeat(12, 45px) 45px;
    gap: 2px;
    color: #ffffff;
    padding: 10px;
    background-color: black;
    border-radius: 0px 0px 0px 10px;
    font-size: 18px;
}

.roulette-grid-scoreboard {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    font-weight: 900;
    background-color: black;
    color: white;
    font-family: digital-7 Mono;
    gap: 10px;
}

.roulette-panel-row-container.userbalance {
    color: white;
    font-family: digital-7 Mono;
    font-weight: 900;
    font-size: 20px;
}

.roulette-grid-scoreboard-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 120px;
    gap: 4px;
}

.scoreboard-text-left {
    display: flex;
    justify-content: end;
    width: 100px;
}

.scoreboard-text-right {
    display: flex;
    justify-content: end;
    width: 33px;
    color: var(--highlight-text)
}

.roulette-grid-scoreboard-row-section {
    display: flex;
    justify-content: right;
    width: 100%;
    flex: 1;
}

.roulette-grid-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-weight: 900;
}

.roulette-grid-logo.top {
    grid-column: 1 / 4;
    grid-row: 1;
}

.roulette-grid-logo.bottom {
    grid-column: 1 / 4;
    grid-row: 14;
}

.roulette-grid-logo-accent {
    color: var(--highlight-text);
}

.roulette-grid-square {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #7d7d7d;
    height: 100%;
    width: 100%;
    background-color: rgb(19, 95, 19);
    cursor: pointer;
    overflow: hidden;
}

.roulette-grid-square::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.418);/* Black with opacity */
    pointer-events: none; /* So it doesn't block clicks */
    display: none; /* Hide by default */
}

.roulette-grid-square.disabled::after {
    display: block; /* Show the overlay when the square is disabled */
}

.roulette-grid-square.disabled:hover {
    cursor: not-allowed;
    transform: none; /* Remove the hover effect when the square is disabled */
}

.roulette-grid-square:hover {
    z-index: 1;
    transform: scale(1.1);
}

.roulette-grid-top-square {
    grid-column: 4 / 7;
    grid-row: 1;
    border-radius: 40px 40px 0 0;
}

.roulette-grid-bottom-square {
    grid-column: 4 / 7;
    grid-row: 14;
    border-radius: 0 0 40px 40px;
}

.roulette-grid-middle-square {
    aspect-ratio: 1 / 1;
}

.roulette-grid-square.red {
    background-color: var(--red-square);
}

.roulette-grid-square.black {
    background-color: var(--black-square);
}


.half-square-top {
    grid-column: 2;
    grid-row: 2 / 8;
}

.half-square-bottom {
    grid-column: 2;
    grid-row: 8 / 14;
}

.black-square {
    grid-column: 1;
    grid-row: 2 / 8;
    background-color: var(--black-square);
}

.red-square {
    grid-column: 1;
    grid-row: 8 / 14;
    background-color: var(--red-square);
}

.third-square-top {
    grid-column: 3;
    grid-row: 2 / 6;
}

.third-square-middle {
    grid-column: 3;
    grid-row: 6 / 10;
}

.third-square-bottom {
    grid-column: 3;
    grid-row: 10 / 14;
}

.sideways-text {
    transform: rotate(90deg);
    transform-origin: center center;
    white-space: nowrap;
}

.upsidedown-text {
    transform: rotate(180deg);
    transform-origin: center center;
    white-space: nowrap;
}

/* ROULETTE BETTING PANEL */
.roulette-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 350px;
}

.roulette-panel-row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: black;
    padding: 5px 0px;
    width: 100%;
}

.roulette-panel-chip-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    margin: 5px;
    width: 65px;
    height: 65px;
    /* background-color: rgb(19, 95, 19); */
    background-color: var(--tertiary-button);
}

.roulette-chip-display {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

@font-face {
    font-family: 'digital-7 Mono';
    src: url('../../../../public/digital-7 Mono.ttf') format('truetype');
}