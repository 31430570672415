.page-myAccount {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    color: var(--primary-text);
    width: 100%;
}

.account-title {
    font-size: 24px;
    margin-bottom: 16px;
}

.account-info {
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    max-width: 340px;
    width: 100%;
}

.balance-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}

.label {
    color: var(--secondary-text);
}

.value {
    color: var(--highlight-text);
}

.action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--secondary-text);
    font-weight: bold;
}

.disclaimer {
    font-size: 12px;
    margin: 8px 0px;
    color: var(--error);
    text-align: center;
}