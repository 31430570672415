/* hud.css */
.hud-fixed-position {
    position: fixed;
    top: 150px;
    right: 0;
    z-index: 1000;
    width: 500px;
    /* Full width when extended */
    transition: right 0.25s ease-in-out;
    /* Smooth transition for sliding in and out */

    /*Mobile*/
    @media (max-width: 1200px) {
        display: none;
        /* Full width when extended */
    }
}

.hud-retracted {
    right: -340px;
    /* Retract almost fully, leaving a tab visible */
}

.hud-hover-extended {
    right: -40px;
    /* Slightly retract when hovered over in extended state */
}

.hud-hover-retracted {
    right: -300px;
    /* Slightly extend when hovered over in retracted state */
}

.hud-balance-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: 5px;
    background-color: var(--secondary-dark);
    box-shadow: 0 2px 4px var(--black-square);
    border: var(--dotted-border-solid);
    border-radius: 10px 0 0 10px;
    padding: 15px;
    width: 100%;
    cursor: pointer;
}


.hud-balance-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    font-weight: 600;
    color: var(--secondary-text);
}

.hud-refresh {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-text);
    cursor: pointer;
    border-radius: 50%;
}

.hud-refresh:hover {
    background-color: var(--primary-text);
}

.hud-balance-display-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 35px;
}

.hud-balance-display {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    color: var(--primary-text);
    min-width: 100px;
}

.hud-balance-changed-animation {
    animation: scaleAnimation 1s ease-in-out;
}

.hud-balance-change {
    display: flex;
    justify-content: flex-end;
    width: 130px;
    position: absolute;
    font-size: 20px;
    font-weight: 600;
    top: 35px;
    left: 0px;
    animation: fadeOut 5s ease-out;
}

.hud-balance-change.increase {
    color: var(--win-clr-light);
    /* background-color: var(--win-clr); */
    /* color: var(--primary-text); */
    /* Updated to use a color variable */
}

.hud-balance-change.decrease {
    color: var(--lose-clr-light);
    /* background-color: var(--lose-clr);
    color: var(--primary-text); */
    /* Updated to use a color variable */
}

@keyframes scaleAnimation {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translateY(0);
        /* Start without moving */
    }

    100% {
        opacity: 0.5;
        transform: translateY(-60px);
        /* Move up as it fades */
        visibility: hidden;
    }
}