.page-farm {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - var(--header-height));
    width: 100%;
    background-image:
        linear-gradient(rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.4)),
        url('https://arcade-web-images.s3.amazonaws.com/backgrounds/farm_background.webp');
    background-size: cover;
    background-position: center;
}

.page-farm-card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.trade-shares-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    /* Adjust the space between items */
    width: 100%;
    max-width: 1000px;
}

.trade-shares-card {
    background-color: var(--secondary-dark);
    border-radius: 10px;
    padding: 20px;
    color: var(--primary-text);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    margin: 15px 5px;
}

.trade-shares-card.buy {
    border: 2px solid var(--success);
}

.trade-shares-card.sell {
    border: 2px solid var(--error);
}

.trade-shares-card-title {
    font-size: 1.5em;
    /* or 24px, adjust size as needed */
    margin-bottom: 20px;
    /* space between title and subtitle */
    text-align: center;
    /* if you want the text to be centered */
    font-weight: bold;
}

.trade-shares-card-subtitle {
    font-size: 1.2em;
    /* or 18px, adjust as needed */
    margin-bottom: 10px;
    /* space between subtitle and input or value */
    text-align: left;
    /* adjust based on your design preference */
    color: var(--highlight-text);
    /* if you want it to stand out */
}

.trade-shares-card-subtitle-value {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 1em;
    /* or 16px, typical size for content */
    margin-bottom: 20px;
    /* space before the next section */
    text-align: left;
    /* adjust based on your design preference */
    color: var(--primary-text);
    position: relative;
}

.trade-shares-estimate-output {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    position: absolute;
    right: 3px;
    color: black;
    font-size: 12px;
    color: rgb(95, 95, 95);
    font-weight: 600;
}

.trade-shares-card-input {
    width: 100%;
    /* Full width */
    padding: 12px;
    /* Some padding */
    border: none;
    /* Gray border */
    border-radius: 4px;
    /* Rounded borders */
    box-sizing: border-box;
    /* Making sure padding doesn't affect the final size */
    box-shadow: inset 0px 2px 2px 0 rgba(0, 0, 0, 0.5);
    position: relative;
}

.trade-shares-card-input:active.buy,
.trade-shares-card-input:focus.buy {
    outline: 2px solid var(--success);
}

.trade-shares-card-input:active.sell,
.trade-shares-card-input:focus.sell {
    outline: 2px solid var(--error);
}

.trade-shares-balance-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* Adjust the space between items */
    margin-bottom: 20px;
    /* Space below the whole section */
}

.trade-shares-balance-info-title,
.trade-shares-balance-info-value {
    margin: 0;
    /* Remove default margins */
    font-size: 14px;
    /* Smaller font size for compact area */
    line-height: 1.4;
    /* Adjust according to your design preference */
}

.trade-shares-balance-info-title {
    font-weight: 500;
    /* Semi-bold */
    color: var(--highlight-text);
}

.trade-shares-balance-info-value {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 400;
    /* Regular weight */
    color: var(--secondary-text);
}

.trade-shares-button-sell {
    background-color: var(--error);
    color: var(--error-text);
}

.trade-shares-button-sell:hover {
    background-color: var(--error-secondary);
    color: var(--tertiary-button-hover-text);
}

.trade-shares-button-sell:active {
    background-color: var(--error-tertiary);
    color: var(--tertiary-button-active-text);
}

.trade-shares-button-buy {
    background-color: var(--success);
    color: var(--success-text);
}

.trade-shares-button-buy:hover {
    background-color: var(--success-secondary);
    color: var(--tertiary-button-hover-text);
}

.trade-shares-button-buy:active {
    background-color: var(--success-tertiary);
    color: var(--tertiary-button-active-text);
}