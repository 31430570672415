.infobar {
    width: 100%;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    flex-direction: row;
    font-family: digital-7 Mono;
    background-color: var(--soft-background);
    color: rgb(235, 235, 235);
    font-size: 20px;
    font-weight: 900;
}

.infobar-info-value {
    margin-left: 5px;
    color: var(--highlight-text);
}