.tetrisScore {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    text-align: center;
    width: 120px;
    height: 100%;
    background-color: var(--primary-dark);
    border-radius: 5px;
    padding: 10px 0px;
}

.tetrisScore_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5px;
}

.tetrisScore_title {
    color: var(--primary-text);
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.tetrisScore_score {
    font-size: 20px;
    font-weight: bold;
    font-family: digital-7 Mono;
    color: var(--highlight-text);
}

/* Mobile styles for when width is less than 1200 */
@media (max-width: 1200px) {
    .tetrisScore {
        width: 100px;
    }

    .tetrisScore_title {
        font-size: 15px;
    }

    .tetrisScore_score {
        font-size: 15px;
    }
}