.tetrisControls-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 2px solid black;
    border-radius: 5px;
}

.tetrisControls-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 5px;
}

.tetrisControls-button {
    width: 30px;
    height: 16px;
    font-size: 20px;
}