.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.modal-container {
    text-align: center;
    max-width: 600px;
    min-width: 300px;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: var(--dotted-border-primary);
    background-color: var(--secondary-dark);
}

.button-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.button-provider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    font-size: 20px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
}

.button-provider-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
}

.button-icon {
    background-color: white;
    height: 20px;
}
