.paginatedList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0px;
    gap: 15px;
    color: var(--primary-text);
    font-weight: 900;
    font-family: digital-7 mono;
    font-size: 20px;
}

.paginatedList-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--highlight-text);
    font-size: 30px;
    cursor: pointer;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.paginatedList-btn:hover {
    background-color: var(--tertiary-dark);
}

.paginatedList-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;
}