.App {
  display: flex;
  min-height: calc(100vh - var(--header-height) - calc(var(--dotted-border-width)*1));
  background-color: var(--secondary-dark);
}

.App-link {
  color: #61dafb;
}

