.tetrisUpcomingBlocks {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 120px;
    min-height: 330px;
    height: 100%;
    background-color: var(--primary-dark);
    border-radius: 5px;
    padding: 10px 0px;
}

.tetrisUpcomingBlocks_title {
    color: var(--primary-text);
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.tetrisUpcomingBlocks_blocks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}

.tetrisUpcomingBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.tetrisUpcomingBlock_row {
    display: flex;
}

.tetrisUpcomingBlock_cell {
    width: 20px;
    height: 20px;
    margin: 1px;
    border-radius: 2px;
    box-sizing: border-box;
}

.tetrisUpcomingBlock_cell.E {
    background-color: transparent;
}

.tetrisUpcomingBlock_cell.I {
    background-color: var(--tetris-cell-I);
}

.tetrisUpcomingBlock_cell.J {
    background-color: var(--tetris-cell-J);
}

.tetrisUpcomingBlock_cell.L {
    background-color: var(--tetris-cell-L);
}

.tetrisUpcomingBlock_cell.O {
    background-color: var(--tetris-cell-O);
}

.tetrisUpcomingBlock_cell.S {
    background-color: var(--tetris-cell-S);
}

.tetrisUpcomingBlock_cell.T {
    background-color: var(--tetris-cell-T);
}

.tetrisUpcomingBlock_cell.Z {
    background-color: var(--tetris-cell-Z);
}

/* Mobile styles for when width is less than 1200 */
@media (max-width: 1200px) {
    .tetrisUpcomingBlocks {
        width: 100px;
        min-height: 200px;
    }

    .tetrisUpcomingBlocks_title {
        font-size: 15px;
    }

    .tetrisUpcomingBlock_cell {
        width: 10px;
        height: 10px;
    }
}