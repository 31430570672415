.game-history-container {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    height: 100%;
    color: var(--primary-text);
    background-color: black;
    border-radius: 10px 10px 0px 0px;
}

.game-history-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 340px;
}

.game-history-pagination-num {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
}

.game-history-pagination-num.hidden {
    display: none;
}

.game-history-pagination-num.active {
    background-color: var(--primary-button);
}

.game-history-roulette-info-table {
    text-align: center;
    font-size: 11px;
}

.game-history-roulette-info-table th,
.game-history-roulette-info-table td {
    outline: 1px dashed var(--secondary-dark);
    width: 200px;
}

.game-history-roulette-info-table th {
    background-color: var(--primary-dark);
    color: var(--highlight-text);
    font-size: 14px;
    height: 50px;
}

.game-history-roulette-info-table tr {
    height: 23px;
}

.game-history-view-btn {
    width: 300px;
}