.pnf-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: var(--primary-text);
}

/* PageNotFound.css */
.pnf-404 {
    font-weight: bolder;
    font-size: 180px;
    line-height: 85%;
}

.pnf-404-accent {
    color: var(--primary-button);
}

.pnf-pnf {
    font-weight: bold;
    font-size: 42px;
    color: var(--primary-button);
}

.pnf-pnf-accent {
    color: var(--primary-text);
}
