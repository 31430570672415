/* Global CSS file */
:root {
    /* Backgrounds */
    --primary-dark: #121212;
    --secondary-dark: #191919;
    --tertiary-dark: #242424;
    --soft-background: #2E2E2E;
    --felt-background: #143317;


    --bs-beige: #fff2cc;
    /* Text Colors */
    --primary-text: #FFFFFF;
    --secondary-text: #B0B0B0;
    --highlight-text: #FFD700;
    --highlight-text-transparency: #ffd90091;

    /* Button and Accent Colors */
    --primary-button: #FFD700;
    --secondary-button: #fff2cc;
    --tertiary-button: #707070;
    --vibrant-accent: #FFA500;

    /* Primary Button Colors*/
    --primary-button: #FFD700;
    --primary-button-hover: #ad9100;
    --primary-button-active: #5a4d00;
    --primary-button-text: #111111;
    --primary-button-hover-text: #1e1e1e;
    --primary-button-active-text: #ffffff;

    /* Secondary Button Colors */
    --secondary-button: #fff2cc;
    --secondary-button-hover: #e6d5b0;
    --secondary-button-active: #b3a387;
    --secondary-button-text: #1e1e1e;
    --secondary-button-hover-text: #3e3e3e;
    --secondary-button-active-text: #ffffff;

    /* Wallet Button Colors */
    --tertiary-button: #539E42;
    --tertiary-button-hover: #407f32;
    --tertiary-button-active: #245b18;
    --tertiary-button-text: #ffffff;
    --tertiary-button-hover-text: #efefef;
    --tertiary-button-active-text: #d8d8d8;

    /* Quad Button Colors */
    --quad-button: #00aaff;
    --quad-button-hover: #ad9100;
    --quad-button-active: #5a4d00;

    /* Gaming Colors*/
    --red-square: #950000;
    --black-square: #0e0e0e;

    /* Positive/Negative/Neutral Colors*/
    --positive-clr: #096733;
    --negative-clr: #783030;
    --neutral-clr: rgb(143, 130, 65);

    /* Gold, Silver, Bronze, and free Button Colors */
    --gold-button: #FFD700;
    --gold-button-hover: #ad9100;
    --gold-button-active: #5a4d00;

    --silver-button: #C0C0C0;
    --silver-button-hover: #919191;
    --silver-button-active: #404040;

    --bronze-button: #CD7F32;
    --bronze-button-hover: #a3662d;
    --bronze-button-active: #7a4f24;

    --free-button: #539E42;
    --free-button-hover: #407f32;
    --free-button-active: #245b18;

    /* Win Lose Draw colors*/
    --win-clr: #08501f;
    --win-clr-light: #108c3b;
    --lose-clr: #5c1515;
    --lose-clr-light: #a02222;
    --draw-clr: var(--secondary-dark);


    /* Error and Success */
    --error: #FF4D4D;
    --error-secondary: #ca0d0d;
    --error-tertiary: #740000;
    --success: #00B894;
    --success-secondary: #00816a;
    --success-tertiary: #00483d;

    /* Sizes */
    --header-height: 80px;
    --header-height-mobile: 50px;
    --footer-height: 10vh;
    --sidebar-width: 300px;
    --dotted-border-width: 4px;

    --inset-border-width: 2px;

    /* Borders */
    /* --dotted-border-primary: var(--dotted-border-width) dotted var(--primary-button); */
    --dotted-border-primary: var(--dotted-border-width) dotted #ffd90091;
    --dotted-border-solid: var(--dotted-border-width) dotted #ffd900;
    --dotted-border-secondary: var(--dotted-border-width) dotted var(--secondary-button);
    --dotted-border-tertiary: var(--dotted-border-width) dotted var(--tertiary-button);

    --inset-border-primary: var(--inset-border-width) inset #ffd90039;
    --inset-border-primary-active: var(--inset-border-width) inset #ffd900bf;

    /* Tetris Colors */
    --tetris-cell-Empty: var(--secondary-dark);
    --tetris-cell-I: #FC5D96;
    --tetris-cell-J: #F7465B;
    --tetris-cell-L: #1DACFE;
    --tetris-cell-O: #ffff30;
    --tetris-cell-S: #ffaf10;
    --tetris-cell-T: #5DD71C;
    --tetris-cell-Z: #A349A4;

    --tetris-cell-I-lighter: #ff79aa;
    --tetris-cell-J-lighter: #f75d6f;
    --tetris-cell-L-lighter: #4abcfd;
    --tetris-cell-O-lighter: #fed44a;
    --tetris-cell-S-lighter: #ffbd3a;
    --tetris-cell-T-lighter: #76dc3f;
    --tetris-cell-Z-lighter: #a15ba2;

    --tetris-cell-I-darker: #b6416c;
    --tetris-cell-J-darker: #b42a3a;
    --tetris-cell-L-darker: #0f73ad;
    --tetris-cell-O-darker: #af8c17;
    --tetris-cell-S-darker: #956403;
    --tetris-cell-T-darker: #30780a;
    --tetris-cell-Z-darker: #521953;

}

/* Usage in a component's CSS */

.small-warning-text {
    font-size: 12px;
    color: var(--error-tertiary);
}

.green-felt {
    background-color: var(--felt-background);
    /* Base color for the felt */
    box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.5);
    /* Adds depth */
    /* The noise effect */
    background-image: repeating-linear-gradient(0deg,
            rgba(255, 255, 255, 0.05) 1px,
            transparent 1px,
            transparent 3px),
        repeating-linear-gradient(90deg,
            rgba(255, 255, 255, 0.05) 1px,
            transparent 1px,
            transparent 3px);
    background-blend-mode: overlay;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
    margin: 8px;
    font-weight: bold;
    text-align: center;
    gap: 5px;

    /* Non-selectable */
    -webkit-user-select: none;
    /* Chrome, Safari, Opera */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Standard syntax */
}

.button.hidden {
    display: none;
}

.button.disabled {
    background-color: var(--tertiary-dark) !important;
    color: var(--secondary-text) !important;
    cursor: not-allowed !important;
}

.button.disabled:hover {
    background-color: var(--tertiary-dark) !important;
    color: var(--secondary-text) !important;
}

.button.xxsmall {
    padding: 2px 4px;
    font-size: 13px;
}

.button.xsmall {
    padding: 4px 8px;
    font-size: 13px;
}

.button.small {
    padding: 8px 16px;
    font-size: 14px;
}

.button.medium {
    padding: 12px 24px;
    font-size: 16px;
}

.button.large {
    padding: 16px 32px;
    font-size: 18px;
}

.button.xlarge {
    padding: 20px 40px;
    font-size: 20px;
}

.button.xxlarge {
    padding: 22px 44px;
    font-size: 22px;
}

.button-primary {
    background-color: var(--primary-button);
    color: var(--primary-button-text);
}

.button-primary:hover {
    background-color: var(--primary-button-hover);
    color: var(--primary-button-hover-text);
}

.button-primary:active {
    background-color: var(--primary-button-active);
    color: var(--primary-button-active-text);
}

.button-secondary {
    background-color: var(--secondary-button);
    color: var(--secondary-button-text);
}

.button-secondary:hover {
    background-color: var(--secondary-button-hover);
    color: var(--secondary-button-hover-text);
}

.button-secondary:active {
    background-color: var(--secondary-button-active);
    color: var(--secondary-button-active-text);
}

.button-tertiary {
    background-color: var(--tertiary-button);
    color: var(--tertiary-button-text);
}

.button-tertiary:hover {
    background-color: var(--tertiary-button-hover);
    color: var(--tertiary-button-hover-text);
}

.button-tertiary:active {
    background-color: var(--tertiary-button-active);
    color: var(--tertiary-button-active-text);
}

.button-cancel {
    background-color: var(--error);
    color: var(--primary-button-text);
}

.button-cancel:hover {
    background-color: var(--error-secondary);
    color: var(--primary-button-hover-text);
}

.button-cancel:active {
    background-color: var(--error-tertiary);
    color: var(--primary-button-active-text);
}

.button-free {
    background-color: var(--free-button);
    color: var(--tertiary-button-text);
}

.button-free:hover {
    background-color: var(--free-button-hover);
    color: var(--tertiary-button-hover-text);
}

.button-free:active {
    background-color: var(--free-button-active);
    color: var(--tertiary-button-active-text);
}

.button-gold {
    background-color: var(--gold-button);
    color: var(--primary-button-text);
}

.button-gold:hover {
    background-color: var(--gold-button-hover);
    color: var(--primary-button-hover-text);
}

.button-gold:active {
    background-color: var(--gold-button-active);
    color: var(--primary-button-active-text);
}

.button-silver {
    background-color: var(--silver-button);
    color: var(--primary-button-text);
}

.button-silver:hover {
    background-color: var(--silver-button-hover);
    color: var(--primary-button-hover-text);
}

.button-silver:active {
    background-color: var(--silver-button-active);
    color: var(--primary-button-active-text);
}

.button-bronze {
    background-color: var(--bronze-button);
    color: var(--primary-button-text);
}

.button-bronze:hover {
    background-color: var(--bronze-button-hover);
    color: var(--primary-button-hover-text);
}

.button-bronze:active {
    background-color: var(--bronze-button-active);
    color: var(--primary-button-active-text);
}

.deep-button {
    color: #000;
    box-shadow: 0 8px #000000;
    transition: all 0.1s ease-in-out;
}

.deep-button:hover {
    /* Slightly pressed in on hover */
    box-shadow: 0 4px #000000;
    transform: translateY(4px);
}

.deep-button:active,
.deep-button.pressed {
    /* Fully pressed in on active */
    box-shadow: 0 1px #000000;
    transform: translateY(7px);
}


/* Usage in a component's HTML */
.non-selectable {
    -webkit-user-select: none;
    /* Chrome, Safari, Opera */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Standard syntax */
}

.page {
    min-height: calc(100vh - var(--header-height));
}