.tetrisBoard-menu-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.577);
}

.tetrisBoard-menu-container.isPlaying {
    display: none;
}

.tetrisBoard-menu {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    background-color: var(--primary-dark);
    padding: 5px;
    gap: 4px;
    border-radius: 5px;
    width: 280px;
    height: 370px;
    border: 3px solid rgb(1, 32, 69);
    color: var(--primary-text)
}

.tetrisBoard-menu-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: rgba(206, 206, 206, 0.282) 1px solid;
}

.tetrisBoard-menu-title {
    color: var(--primary-text);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
}

.tetrisBoard-menu-key {
    color: var(--primary-text);
    font-size: 11px;
    font-weight: bold;
}

.tetrisBoard-menu-value {
    font-size: 16px;
    color: var(--highlight-text);
    font-family: digital-7 Mono;
}

.tetrisBoard-menu-value-two {
    font-size: 25px;
    color: var(--highlight-text);
    font-family: digital-7 Mono;
}

.tetrisBoard-menu-container-highScores-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
}

.tetrisBoard-menu-container-highScores-col {
    display: flex;
    flex-direction: column;
    gap: 3px;
    flex: 1;
}

.tetrisBoard-menu-container-highScores-col-title {
    font-size: 12px;
    color: var(--secondary-text);
    border-bottom: 1px solid rgba(206, 206, 206, 0.282);
}