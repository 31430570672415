.imageButton {
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
    height: auto;
    border: 2px solid #ffffff3d;
}

.imageButton:hover {
    border: 2px solid var(--highlight-text-transparency);
}