.accountBalance {
    padding: 10px 0px;
}

.accountBalance-title {
    font-size: 30px;
    font-weight: bold;
    color: var(--highlight-text);
    margin-bottom: 30px;
    text-align: center;
}

.accountBalance-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    background-color: var(--secondary-dark);
    gap: 50px;
}

.accountBalance-section-container {
    width: 350px;
}

.accountBalance-section-title {
    font-size: 28px;
    font-weight: bold;
    color: var(--highlight-text);
    margin-bottom: 10px;
}

.accountBalance-section-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--secondary-text);
}

.accountBalance-section-row-value {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 5px;
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-text);
}

.accountBalance-section-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}