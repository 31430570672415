.deposit-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.deposit-modal.open {
    display: flex;
}

.deposit-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 355px;
    box-sizing: border-box;
    padding: 20px;
    background-color: var(--primary-dark);
    border-radius: 5px;
    position: relative;
}

.deposit-modal-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 35px;
    font-weight: bold;
    color: var(--highlight-text);
}

.deposit-modal-content-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.deposit-modal-content-button {
    width: 100%;
}

.deposit-modal-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5px;
}