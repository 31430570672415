.dropdown-container {
  position: relative;
  cursor: pointer;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  z-index: 100;
  background-color: var(--primary-dark);
  outline: 1px dashed var(--primary-button);
  border-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 8px 0 0 0;
  font-size: 12px;
  font-weight: 600;
}

.dropdown-list.middle {
  left: 50%;
  transform: translateX(-50%);
}

.dropdown-list.left {
  right: 0;
}

.dropdown-list.right {
  left: 0;
}

.dropdown-item {
  padding: 8px 44px;
  cursor: pointer;
  color: var(--primary-text);
}

.dropdown-item:hover {
  background-color: var(--secondary-dark);
  color: var(--highlight-text);
}