/* The modal background overlay that covers the entire screen */
.error-modal {
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.8); /* Slightly transparent */
    /* Flexbox to center the modal content vertically and horizontally */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal content box in the center */
.error-modal-content {
    display: flex;
    flex-direction: column;
    background-color: var(--soft-background); /* using soft background color */
    margin: auto;
    padding: 20px;
    border: 1px solid var(--secondary-text); /* using secondary text color for the border */
    width: 300px; /* Can adjust if necessary */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    color: var(--primary-text); /* using primary text color */
}

.error-modal-content h4 {
    color: var(--error); /* using error color for headers */
}

