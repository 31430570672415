/* Overlay that covers the entire screen */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 1);
    /* Semi-transparent black */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.ring {
    position: relative;
    width: 150px;
    height: 150px;
    background: transparent;
    border: 3px solid var(--secondary-dark);
    border-radius: 50%;
    text-align: center;
    line-height: 150px;
    font-family: sans-serif;
    font-size: 20px;
    color: var(--primary-text);
    letter-spacing: 4px;
    text-transform: uppercase;
    text-shadow: 0 0 10px var(--primary-button);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.ring span {
    display: block;
    position: absolute;
    top: calc(50% - 2px);
    left: 50%;
    width: 50%;
    height: 4px;
    background: transparent;
    transform-origin: left;
    animation: animate 2s linear infinite;
}

.ring span:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: var(--primary-button);
    top: -6px;
    right: -8px;
    box-shadow: 0 0 20px var(--secondary-button);
}

@keyframes animate {
    0% {
        transform: rotate(45deg);
    }

    100% {
        transform: rotate(405deg);
    }
}