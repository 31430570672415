.tetris-container-outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #152f06;
    border-radius: 15px;
}

.tetris-container-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px;
    gap: 4px;
    background-color: #428d19;
    border-radius: 5px;
    position: relative;
}