/* Roulette.css */
.roulette-container {
    position: relative;
    width: 350px;
    /* This represents the diameter of the roulette */
    height: 350px;
}

.roulette-wheel-img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #000000;
    z-index: 1;
    /* Create a new layer to improve animation performance */
}

.roulette-middle-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Adjust this value to position it on top of the roulette */
    width: 65%;
    border-radius: 50%;
    background-color: #000000;
    border: 8px solid #4B371C;
    z-index: 2;
}

.roulette-wheel-brown-bg1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 115%;
    height: 115%;
    border-radius: 50%;
    background-color: #352611;
    z-index: 0;
    border: #1f1f1f75 solid 2px;
}

.roulette-wheel-brown-bg2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 105%;
    height: 105%;
    border-radius: 50%;
    background-color: #815e32;
    z-index: 0;
}

.roulette-arrow {
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 50px solid var(--success);
    /* Use your desired color here */
    position: absolute;
    top: 13%;
    /* Adjust as needed to position it correctly */
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;

    /* Rounded tip for the arrow */
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.roulette-spin-btn-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -54.5%);
    /* Adjust this value to position it on top of the roulette */
    width: 65%;
    height: 65%;
    border-radius: 50%;
    z-index: 2;
    box-shadow: inset 10 12px 14px rgba(0, 0, 0, 1);
}

.roulette-spin-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--primary-button);
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
}

.roulette-spin-btn.disabled {
    background-color: var(--tertiary-dark) !important;
    color: var(--secondary-text) !important;
    cursor: not-allowed !important;
}

.roulette-middle-img2 {
    position: absolute;
    width: 100%;
    border-radius: 50%;
    border: 1px solid #000000;
}

.button-3d-effect {
    position: absolute;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.111) 50%, hsla(0, 0%, 0%, 1) 80%);
    z-index: 3;
    width: 100%;
    height: 100%;
}


/*Mobile styles (less than 1200px)*/
@media (max-width: 1200px) {
    .roulette-container {
        width: 250px;
        height: 250px;
    }

    .roulette-arrow {
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 40px solid var(--success);
    }
}