.blackjackPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-image:
        linear-gradient(rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.4)),
        url('https://arcade-web-images.s3.amazonaws.com/backgrounds/hitme_background.webp');
    background-size: cover;
    background-position: center;
}