.tetrisBoard {
    user-select: none;
    background-color: var(--tetris-cell-Empty);
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    border: 2px solid var(--tetris-cell-Empty);
}

.tetrisBoard_row {
    display: flex;
}

@keyframes fade {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.tetrisBoard_row--fade {
    animation: fade 0.5s ease-out forwards;
    /* Flash 3 times in 1.5 seconds */
}